// React imports
import { useEffect } from 'react';

// Gatbsy imports
import { navigate } from 'gatsby';

// local imports
import { pixel } from 'resources';

export default () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const userAgent = window.navigator.userAgent;

            pixel.fb_track('InitialCheckout', window);
            if (/android/i.test(userAgent)) {
                window.location.replace('https://play.google.com/store/apps/details?id=com.cuenca.cuenca_mobile&hl=es');
            } else if (/iPad|iPhone|iPod/.test(userAgent)) {
                window.location.replace('https://itunes.apple.com/mx/app/cuenca/id1447995379?mt=8');
            } else {
                navigate('/');
            }
        }
    }, []);

    return null;
};
